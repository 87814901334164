/* src/components/Timeline.css */
.timeline {
  padding: 50px;
  background-color: #f7f9fc;
  color: #333;
  text-align: center;
}

.timeline h2 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 50px;
}

.timeline-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
  overflow: hidden;
}

.timeline-container::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: #00ffea;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.timeline-item {
  position: relative;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  transition: transform 0.6s, opacity 0.6s;
}

.timeline-item.left {
  left: 0;
  transform: translateX(50%);
}

.timeline-item.right {
  left: 50%;
  transform: translateX(-50%);
}

.timeline-item.in-view {
  opacity: 1;
}

.timeline-item.left.in-view {
  transform: translateX(0%);
}

.timeline-item.right.in-view {
  transform: translateX(0%);
}

.timeline-item-content {
  position: relative;
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.timeline-item-content .date {
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
  color: #00ffea;
  margin-bottom: 10px;
}

.timeline-item-content h3 {
  font-size: 1.5em;
  color: #333;
  margin: 0;
}

.timeline-item-content p {
  font-size: 1em;
  color: #666;
}

.timeline-item-content .circle {
  position: absolute;
  top: 20px;
  width: 20px;
  height: 20px;
  background-color: #00ffea;
  border-radius: 50%;
  left: -10px;
}

.timeline-item.left .circle {
  right: -10px;
  left: auto;
}

@media screen and (max-width: 768px) {
  .timeline-container::after {
    left: 20px;
  }

  .timeline-item {
    width: 100%;
    padding-left: 40px;
    padding-right: 25px;
  }

  .timeline-item.left,
  .timeline-item.right {
    left: 0;
    transform: translateX(0);
  }

  .timeline-item-content {
    padding: 20px;
  }

  .timeline-item.left .circle,
  .timeline-item.right .circle {
    left: -30px;
  }
}




